import { WebFooter } from '@bounty/creators-common/components/web/WebFooter'
import { WebNavigation } from '@bounty/creators-common/components/web/WebNavigation'
import { WebSignUpBanner } from '@bounty/creators-common/components/web/WebSignUpBanner'
import { DocsHoundChat } from "@docshound/chat-react";
import {
  WebWrapper,
  WebWrapperProvider,
} from '@bounty/creators-common/components/web/WebWrapper'
import { ReactNode } from 'react'
//import { useEffect } from "react";

export function Layout({ children }: { children: ReactNode }) {
  /*useEffect(() => {
    const adjustButtonPosition = () => {
      // Find the DocsHound shadow host
      const shadowHost = document.querySelector("docshound-chat") as HTMLElement;
      if (!shadowHost) return false;

      // Access the shadow root
      const shadowRoot = shadowHost.shadowRoot;
      if (!shadowRoot) return false;

      // Find the button inside the shadow DOM
      const button = shadowRoot.querySelector(".dh-fixed");
      if (button) {
        (button as HTMLElement).style.bottom = "20px";
        (button as HTMLElement).style.top = "auto";
        console.log("? DocsHound button position updated!"); // Debugging
        return true;
      }

      return false;
    };

    // Check every 100ms until the button is found
    const interval = setInterval(() => {
      if (adjustButtonPosition()) {
        clearInterval(interval); // Stop checking once found
      }
    }, 100);

    return () => clearInterval(interval); // Cleanup on unmount
  }, []);*/

  return (
    <WebWrapperProvider>
      <WebNavigation />
      <WebWrapper>{children}</WebWrapper>
      <WebFooter />
      <WebSignUpBanner />
      <DocsHoundChat src="https://creatorguide.bounty.co/" />
    </WebWrapperProvider>
  )
}

